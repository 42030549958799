// const axios = require('axios');

// let api = '';
// let apiPath = '';
// let apiPostfix = '';
let player = document.documentElement;
let isFullScreen = false;

// let session = {};

export function resetSession(){
    // session = {};
}

export function openFullscreen() {
  if (!isFullScreen){
    if (player.requestFullscreen) {
      player.requestFullscreen();
    } else if (player.webkitRequestFullscreen) { /* Safari */
      player.webkitRequestFullscreen();
    } else if (player.msRequestFullscreen) { /* IE11 */
      player.msRequestFullscreen();
    }
    isFullScreen = true;
  } else {
    closeFullscreen();
    isFullScreen = false;
  }
}
export function getFullscreen(){
  return isFullScreen;
}

export function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }
  }
  
