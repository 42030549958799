import React from "react";
import { Tween } from "react-gsap";

export default function RuptureSymbol({currentRupture, playState}) {

    const symbol1 = <path d="M0 65.1372H29.7617L32.4358 83.059L35.1537 38.8201L40.723 93.8238L44.43 16.3425L48.5557 120.721L50.436 1L54.4102 111.967L57.2617 21.2373L61.5568 123L63.2498 75.3022L64.9428 27.6044L69.5882 77.5903L73.0752 51.3545L77.3313 88.8685L81.6867 40.0048L85.8662 93.9365L91.9792 52.4861L95.4354 75.8615L100.62 56.945L103.619 65.1372H133.381L136.055 83.059L138.773 38.8201L144.342 93.8238L148.049 16.3425L152.175 120.721L154.055 1L158.029 111.967L160.881 21.2373L165.176 123L168.562 27.6044L173.207 77.5903L176.694 51.3545L180.95 88.8685L185.306 40.0048L189.485 93.9365L195.598 52.4861L199.055 75.8615L204.239 56.945L207.238 65.1372L237 65.1372" id="xl" stroke="white" fill="transparent" strokeOpacity="0.6" strokeWidth="2"/>;
    const symbol2 = <path d="M74 101.7647L119.549 26L160.098 101.7647M184 101.7647L229.549 26L275.098 101.7647M-36 101.7647L9.549 26L55.0981 101.7647" stroke="white" strokeOpacity="0.6" strokeWidth="2" id="triangles-disconnected" fill="transparent" />;
    const symbol3 = <path d="M13 63H223M13 63C41.7939 63 81.3885 51 117.251 51C155.939 51 191.937 63 236 63M13 63C41.7939 63 81.3885 57 117.251 57C155.939 57 191.937 63 236 63M13 63C41.7939 63 81.3885 75 117.251 75C155.939 75 191.937 63 236 63M13 63C41.7939 63 81.3885 69 117.251 69C155.939 69 191.937 63 236 63" stroke="white" strokeOpacity="1" strokeWidth="2" id="strings"/>;
    const symbol4 = <path d="M50 62L120 25L190 62L120 103L50 62Z" stroke="white" strokeOpacity="0.6" strokeWidth="2" id="diamond" fill="transparent" />;
    const symbol5 = <path d="M62 110L117.5 18L173 110M153 110L208.5 18L264 110M-29 110L26.5 18L92 110" stroke="white" strokeOpacity="0.6" strokeWidth="2" fill="transparent" id="triangles-overlap" />;
    const symbol6 = <path d="M15 95H221M73.6308 31H162.369M45.7454 61.439H190.255" stroke="white" strokeOpacity="0.6" strokeWidth="2" id="lines" />;
    const symbol7 = <path d="M1 77.7647L46.549 2L92.0981 77.7647M92.0981 77.7647L137.647 2L183.196 77.7647M183.196 77.7647L228.745 2L274.294 77.7647" stroke="white" strokeOpacity="0.6" strokeWidth="2" id="triables-connected" />;

    const play = <path id="autolysis" d="M90 37L146 62L90 87L90 37Z" stroke="white"/>;


    const getSymbolSrc = ()=>{
        if(currentRupture==='Rupture1') {
            return symbol1;
        } else if (currentRupture==='Rupture2') {
            return symbol2;
        } else if (currentRupture==='Rupture3') {
            return symbol3;
        } else if (currentRupture==='Rupture4') {
            return symbol4;
        } else if (currentRupture==='Rupture5') {
            return symbol5;
        } else if (currentRupture==='Rupture6') {
            return symbol6;
        } else if (currentRupture==='Rupture7') {
            return symbol7;
        } else if (currentRupture==='play') {
            return play;
        }
    }

    let tweenprops;
    if (playState==='fade') tweenprops = {opacity:0};

    let rclass = 'rupture-icon';
    if (playState==='show') rclass += ' in';
    if (playState==='hide') rclass += ' out';

    
    return (
        <Tween to={tweenprops}>
            <svg className={rclass} width="237" height="124" viewBox="0 0 237 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                {getSymbolSrc()}
            </svg>
        </Tween>
    );
}