
import React, {useState, useEffect, useRef} from "react";
import { Reveal, Tween } from "react-gsap";
import autolysisLogo from '../assets/logo.svg';
import bonusFrame from '../assets/project-main.jpg';

const bonusSrc = '/video/ruptures/Autolysis-Bonus-Rupture-compressed.mp4';

function EndFrame({settings,nav}) {

    const [ showBonus, setShowBonus ] = useState(false);
    const [ fScroll, setFScroll ] = useState(0);
    const frame = useRef(null);

    const ptags = settings.excerpt.split('<br><br>')
                .map( (e,i) =><p dangerouslySetInnerHTML={{__html: e}} key={i} className="text-light my-4" />);

    const handleBonusClick = ()=>{
        setShowBonus(true);
    }
    const handleBonusVideoClick = ()=>{
        setShowBonus(false);
    }

    useEffect(()=>{
        const onScroll = () => setFScroll(frame.current.scrollTop);
        // clean up code
        frame.current.removeEventListener('scroll', onScroll);
        frame.current.addEventListener('scroll', onScroll, { passive: true });
        return () => frame.current.removeEventListener('scroll', onScroll);
    },[frame]);

    return (
        <>
            {/* <Tween 
                from={{ y:300,stagger: 0.4, delay:1.5 }}
                to={{ y:0,stagger:0.4,delay:1.5}}
                duration={1.5}
            > */}
                <div className="fullscreen end-frame" ref={frame}>
                    <div className="end-frame-inner w-full text-center">
                        <Tween
                            from={{ opacity:0, stagger: 0.4 }}
                            to={{ opacity:1,stagger:0.4 }}
                            duration={1}
                        >
                            <h1 className={fScroll>0?'py-16 scrolled':'py-16'} id="h1"><img src={autolysisLogo} alt="Autolysis" /></h1>
                        </Tween>
                        <Tween
                            from={{ opacity:0, stagger: 0.4 }}
                            to={{ opacity:1,stagger:0.4,delay:1 }}
                            duration={1}
                        >
                            <div>
                                <div className='w-full mx-auto lg:max-w-4xl flex flex-col justify-center lg:grid lg:grid-cols-2 gap-8 lg:gap-16 px-12'>
                                    {settings.credits.map((credit,index) => {
                                        return (
                                            <div className='artist' key={index} data-index={index}>
                                                 <img src={credit.imgURL} alt={credit.title} />
                                                <div>
                                                <h2 className="mb-2 text-lg leading-6">{credit.title}</h2>
                                                <p className="leading-5">{credit.excerpt}</p>  
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Tween>
                        
                        <div className="w-full max-w-sm mx-auto mt-20">
                            {showBonus ?
                                <video 
                                className='video-player w-screen h-screen z-50 portrait:h-auto portrait:aspect-4/3 object-cover fixed portrait:relative top-0 left-0' 
                                src={bonusSrc}
                                autoPlay={true}
                                playsInline={true}
                                onClick={handleBonusVideoClick}
                                onEnded={handleBonusVideoClick}
                                ></video>
                            : 
                                <img src={bonusFrame} alt="Bonus" className="bg-credits-main h-40 w-52 mx-auto bg-no-repeat bg-cover bg-center cursor-pointer" onClick={handleBonusClick} />
                            }
                            <Reveal repeat>
                                <Tween
                                    from={{opacity:0, stagger:2, duration: 2}}
                                >
                                    { ptags }
                                </Tween>
                            </Reveal>
                        </div>
                        
                    </div>
                </div>
          {/* </Tween> */}
        </>
        
    );
}

export default EndFrame;