import React, { useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";
import EndFrame from "./EndFrame";
// import { Tween } from "react-gsap";
import settings from '../../src/config.json';
import { ReactComponent as PlayBtn } from '../assets/triangle.svg';

export default function MainProject() {

    const mainVideoRef = useRef(null);
    // const ruptureVideoRef = useRef(null);
    const [ currentRupture, setCurrentRupture ] = useState('');
    const [ ruptureShowing, setRuptureShowing ] = useState(false);
    const [ rupturePlaying, setRupturePlaying ]  = useState(false);
    const [ ruptureFading, setRuptureFading ]  = useState(false);
    const [ ruptureBuffered, setRuptureBuffered ] = useState([]);
    const [ endFrameActive, setEndFrameActive ] = useState(false);
    const [ nextRupture, setNextRupture ] = useState(1);
    const [ isReady, setIsReady ] = useState(false);
    const [ isDebug, setIsDebug ] = useState(window.location.search==='?debug');
    // const navigate = useNavigate();

    const mainsrc = '/video/Autolysis Main Timeline_1.mp4';
    const rupturesrc = [
      {'id':'Rupture1', 'src':'/video/ruptures/Autolysis-Rupture-1-compressed.mp4'},
      {'id':'Rupture2', 'src':'/video/ruptures/Autolysis-Rupture-2-compressed.mp4'},
      {'id':'Rupture3', 'src':'/video/ruptures/Autolysis-Rupture-3-compressed.mp4'},
      {'id':'Rupture4', 'src':'/video/ruptures/Autolysis-Rupture-4-compressed.mp4'},
      {'id':'Rupture5', 'src':'/video/ruptures/Autolysis-Rupture-5-compressed.mp4'},
      {'id':'Rupture6', 'src':'/video/ruptures/Autolysis-Rupture-6-compressed.mp4'},
      {'id':'Rupture7', 'src':'/video/ruptures/Autolysis-Rupture-7-compressed.mp4'}
    ];

    const mainTimeline = [
        {cue:0.0,name:'start'},
        {cue:24.0,name:'showRupture1'}, // 24:00
        {cue:28.01,name:'fadeRupture1'}, // 30:01
        {cue:30.01,name:'hideRupture1'}, // 30:01
        {cue:99.12,name:'showRupture2'}, // 1:39:12
        {cue:113.04,name:'fadeRupture2'}, // 1:55:04
        {cue:115.04,name:'hideRupture2'}, // 1:55:04
        {cue:154.12,name:'showRupture3'}, // 2:34:12
        {cue:161.16,name:'fadeRupture3'}, // 2:43:16
        {cue:163.16,name:'hideRupture3'}, // 2:43:16
        {cue:252.06,name:'showRupture4'}, // 4:12:06
        {cue:263.02,name:'fadeRupture4'}, // 4:25:02
        {cue:265.02,name:'hideRupture4'}, // 4:25:02
        {cue:324.09,name:'showRupture5'}, // 5:24:09
        {cue:335.12,name:'fadeRupture5'}, // 5:37:12
        {cue:337.12,name:'hideRupture5'}, // 5:37:12
        {cue:526.01,name:'showRupture6'}, // 8:46:01
        {cue:535.14,name:'fadeRupture6'}, // 8:57:14
        {cue:537.14,name:'hideRupture6'}, // 8:57:14
        {cue:588.09,name:'showRupture7'}, // 9:48:09
    ];
    

    const onMainChangeState = (id,val)=>{
        console.log('received state change',val);
        if (val.includes('show')){
          // show symbol
          setRuptureShowing(true);
          setRuptureFading(false);
          setCurrentRupture(val.substring(4));
          setNextRupture( Number(val.substring(11))+1 );
        } else if (val.includes('fade')){
          setRuptureFading(true);
        } else if (val.includes('hide')){
          // hide symbol
          setRuptureShowing(false);
        } else if (val==='start'){
          setRuptureShowing(false);
        } else if (val==='complete'){
          setRuptureShowing(false);
          if (endFrameActive === false) {
            setEndFrameActive(true);
          } else {
            setEndFrameActive(false);
          }
        }
      };
    
      const handleRuptureClick = (play)=>{
        console.log('click rupture',play);
        // mainVideoRef.current.pause();
        if (play){
          // setRuptureBuffered(false);
          mainVideoRef.current.pause();
          setRupturePlaying(true);
        } else {
          onRuptureChangeState(currentRupture,'complete');
        }
      };
    
      /* const getRuptureSrc = ()=>{
        // decide which rupture to play based on currentRupture.
        return rupturesrc[currentRupture];
      } */
      
      const onRuptureChangeState = (id,val)=>{
        if (val==='complete'){
          // setRuptureBuffered(false);
          setRupturePlaying(false);
          setRuptureShowing(false);
          mainVideoRef.current.resume();
          console.log(currentRupture);
        } else if (val==='buffered'){
          setRuptureBuffered([ ...ruptureBuffered, id ]);
          // mainVideoRef.current.pause();
        }
      }
    
    useEffect(() => {
      const nav = document.querySelector('nav');

      // const isdebug = window.location.search==='?debug';
      console.log('isdebug',isDebug);

      let timeout = setTimeout(hideNav, 5000);
      function showNav() {
        clearTimeout(timeout);
        nav.style.display = "flex";
        nav.style.opacity = "1";
        nav.style.transition = "all .5s ease";
        timeout = setTimeout(hideNav, 5000);
      }
      function hideNav() {
        // nav.classList.add('hidden');
        nav.style.opacity = "0";
        nav.style.transition = "all .5s ease";
      }
      document.addEventListener('mousemove', (event) => {
        // only show nav if moving around top of screen.
        if (event.clientY<=100) showNav();
      });
    });

    useEffect( ()=>{
      console.log('buffer',ruptureBuffered);
      // if (ruptureBuffered.length>=rupturesrc.length){
      if (ruptureBuffered.includes(rupturesrc[0].id)){
        console.log('buffered enough');
        setIsReady(true);
      }
    }, [ruptureBuffered] )

    return (
        
          // <Tween 
          //   from={{ opacity:0, stagger: 0.4, delay:.4 }}
          //   to={{ opacity:1,stagger:0.4,delay:.4 }}
          // >
            <>
            <div className="video-wrap">
              <VideoPlayer num={1} src={mainsrc} id="main" cues={mainTimeline} onStateChange={onMainChangeState} ref={mainVideoRef} ruptureShowing={ruptureShowing} rupturePlaying={rupturePlaying} ruptureFading={ruptureFading} onRuptureClick={handleRuptureClick} currentRupture={currentRupture} isPlaying={false} isReady={isReady} isDebug={isDebug} />
              { rupturesrc.map( (r,i) => 
                  <VideoPlayer num={i+2} src={r.src} id={r.id} key={r.id} onStateChange={onRuptureChangeState}  ruptureShowing={true} currentRupture={currentRupture} nextRupture={nextRupture} isPlaying={(r.id===currentRupture) && rupturePlaying} isDebug={isDebug} />
                )}
                <PlayBtn id="loading" alt="loading" className={isReady?'rupture-icon cycling hide':'rupture-icon cycling'} />
              {/* <img id="loading" src={loading} alt="loading" className={isReady?'hide':''} /> */}
            </div>
            {endFrameActive && <EndFrame settings={settings.endframe} /> }
            </>
          // </Tween>
    );
}
