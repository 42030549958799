import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import RuptureSymbol from "./RuptureSymbol";
import { Tween } from "react-gsap";
import lightning from "../assets/lightning.svg";

const VideoPlayer = forwardRef(( props, ref ) => {
    
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(props.isPlaying);
    const [currentCue, setCurrentCue] = useState('');
    const [currentTimeSec, setCurrentTimeSec] = useState(-1); //current time of the video in seconds
    const [durationSec, setDurationSec] = useState(-1); // // current duration of the video in seconds

    const [firstTime, setIsFirstTime] = useState(props.num===1);

    const [isBuffered,setBuffered] = useState(false);

    const [autoPlay,setAutoPlay] = useState(props.num===2);

    const handlePlayClick = () => {
        // on video 1, rupture symbol showing, play the rupture
        if (props.num===1 && props.ruptureShowing && !props.rupturePlaying){
          props.onRuptureClick(true);
          return;
        }
        // on video 1, rupture already playing. kill the rupture
        if (props.num===1 && props.ruptureShowing && props.rupturePlaying){
          props.onRuptureClick(false);
          return;
        }

        // if (isPlaying) {
        //   videoRef.current.pause();
        //   setIsPlaying(false);
        // } else {
          videoRef.current.play();
          setIsPlaying(true);
        // }

        setIsFirstTime(false);
    }
    
    const handleCanPlay = () => {
      console.log('buffered');
      setBuffered(true);
      if (props.num>1){
        // tell parent that we can play now.
        console.log(props.num,'can play!');
        props.onStateChange(props.id,'buffered');
      }
    }
    
    function handleBackClick(){
      console.log('back click');
      window.location.href = 'https://playreplay.plusk.com';
      return false;
    }

    useEffect(() => {
        console.log(props.id,'useEffect',isPlaying);

        // console.log(videoRef.current.duration);
        setDurationSec(videoRef.current.duration);
    
        if (isPlaying) {
          const interval = setInterval(async () => {
            setCurrentTimeSec(videoRef.current.currentTime);

            if (durationSec<0) setDurationSec(videoRef.current.duration);
          }, 100);
          return () => clearInterval(interval);
        } else {
          console.log('paused video');
        }
    }, [isPlaying, videoRef]);

    useEffect( ()=>{


        if (videoRef && videoRef.current.currentTime > 0) {
            // get current cue.
            if (props.cues){
              const _cue = props.cues.findLast(c=>c.cue<=currentTimeSec);
              if (_cue && _cue.name!==currentCue) {
                  console.log('detect cue change',_cue.name);
                  props.onStateChange(props.id,_cue.name,currentTimeSec);
                  setCurrentCue(_cue.name);
              }
            }
            if (currentTimeSec===durationSec){
                props.onStateChange(props.id,'complete',currentTimeSec);
                setIsPlaying(false);
            }
        }
    }, [currentTimeSec]);

    useEffect( ()=>{
      // console.log(props.id,'detect change',props.rupturePlaying);
      if (props.num>1) {
        setIsPlaying(props.isPlaying);
        if (props.isPlaying) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }
    },[props.isPlaying]);

    useEffect( ()=>{
      if (props.num>1){
        setAutoPlay( (props.nextRupture+1)==props.num );
      }
    },[props.nextRupture]);

    useImperativeHandle(ref, () => ({
        pause() {
          console.log("child pause");
          videoRef.current.pause();
          setIsPlaying(false);
        },
        resume() {
            console.log("child resume");
            videoRef.current.play();
            setIsPlaying(true);
        }
    }));

    let playerClass = 'player-wrap';
    if (props.num===1 && props.rupturePlaying) playerClass += ' half buffered';
    if (props.num===1 && firstTime) playerClass += ' waiting';
    if (props.num>1) playerClass += ' half';
    if (props.num>1 && props.id===props.currentRupture && !props.isPlaying) playerClass += ' hidden';
    if (props.num>1 && props.id!==props.currentRupture) playerClass += ' hidden';
    if (props.isDebug) playerClass += ' debug';
    // if (!isBuffered && props.num===2) playerClass += ' buffering';

    let rupturePlayState = 'showing';
    if (props.num===1 && props.ruptureShowing) {
      if (props.rupturePlaying){
        rupturePlayState = 'fade'; // video 1, rupture is already on screen, we want to fade it out.
      } else if (props.ruptureFading){
        rupturePlayState = 'hide'; // video 1, hit fade keyframe, transition out.
      } else {
        rupturePlayState = 'show'; // video 1, rupture showing but not playing.
      }
    }
    if (props.num>1) rupturePlayState = 'hide'; // video 2. rupture is already on screen, we want to draw it out.

    return (
      <section id={`video${props.num}`} className={playerClass}>
        {/* <Tween 
          from={{ opacity:0, stagger: 0.4, delay:.4 }}
          to={{ opacity:1,stagger:0.4,delay:.4 }}
        > */}
        <video 
          className='video-player' 
          src={props.src}
          ref={videoRef}
          autoPlay={autoPlay}
          preload="auto"
          playsInline={true}
          onClick={handlePlayClick}
          onCanPlay={handleCanPlay}
          ></video>
          <div className="video-controls">
              {durationSec>0 && <input
              type="range"
              min="0"
              max={durationSec}
              default="0"
              value={currentTimeSec}
              className="timeline"
              onChange={(e) => {
                  setCurrentTimeSec(e.target.value);
                  videoRef.current.currentTime = e.target.value;
              }}
              />}
          </div>
        {/* </Tween> */}
        {props.ruptureShowing && 
          <RuptureSymbol currentRupture={props.currentRupture} playState={rupturePlayState} />
        }
        {firstTime && props.isReady &&
          <Tween wrapper={<div className="trigger-warning" />} from={{opacity:0, y: 30, duration: .5, stagger:.2}}>
          {/* <div className="trigger-warning"> */}
            
            <p>
              <img src={lightning} alt="Trigger warning" />
              <strong>Warning:</strong> This experience contains scenes with a strobe lighting effect that may trigger a seizure for people with visual sensitivity.</p>
            <p>
              <button onClick={handlePlayClick}>Continue</button> or <button onClick={handleBackClick}>Go Back</button>
            </p>
          {/* </div> */}
          </Tween>
          
          // <RuptureSymbol currentRupture={'play'} playState={'show'} />
        }
        {/* {!isBuffered && props.num>1 &&
          <div className="bufferBar" />
        } */}
      </section>
    )
});

export default VideoPlayer;